.container {
  max-width: 1015px;
  margin: 13px auto 200px auto;
  font-family: Roboto;
}

.title {
  margin-top: 90px;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.paragraph {
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 26px;
}

.subTitle {
  margin-top: 33px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

.list {
  margin-top: 14px;
  margin-left: 20px;
  font-size: 16px;
  line-height: 26px;
}

.list li {
  margin-bottom: 30px;
}

.highlight {
  color: #3577f6;
}

@media (max-width: 1025px) {
  .container {
    padding: 0 20px;
  }
}

@media (max-width: 426px) {
  .title {
    margin-top: 50px;
  }
}
