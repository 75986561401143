.container {
  font-family: Roboto;
  margin: 13px 0 160px 0;
}

.breadcrumbsContainer {
  max-width: 1125px;
  margin: 0 auto;
}

.firstScreen {
  max-width: 1125px;
  margin: 0 auto;
  margin-top: 62px;
  display: flex;
  justify-content: space-between;
}

.title,
.subTitle {
  font-family: Bebas;
  margin: 0;
}

.title {
  margin-top: 85px;
  font-size: 44px;
  font-weight: 400;
  line-height: 53px;
}

.subTitle {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  margin-bottom: 40px;
}

.info {
  max-width: 445px;
}

.info p {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 32px;
  color: #828282;
}

.bold {
  font-weight: 700;
}

.img {
  position: relative;
  max-width: 614px;
  height: 481px;
  width: 100%;
  background-image: url('assets/img/bp-iPad.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.img::after {
  content: '';
  position: absolute;
  top: 200px;
  left: 250px;
  width: 822px;
  height: 489px;
  background-image: url('assets/img/mp-macBook1.png');
}

.img::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 305px;
  left: 225px;
  max-width: 367px;
  width: 100%;
  height: 400px;
  background-image: url('assets/vector/bp-blob1.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.formContainer {
  max-width: 805px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  max-width: 684px;
  margin-top: 40px;
}

.formTitle {
  margin-top: 101px;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
}

.note {
  margin-top: 13px;
  font-size: 10px;
  line-height: 12px;
}

@media (max-width: 1025px) {
  .container {
    padding: 0 20px;
  }

  .img::after {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .firstScreen {
    margin-top: 20px;
    flex-direction: column;
  }

  .info {
    max-width: 800px;
    margin: 0 auto;
  }

  .info .title {
    margin-top: 30px;
  }

  .img {
    margin-left: 70px;
  }

  .img::before {
    display: none;
  }

  .formContainer {
    margin-top: 20px;
  }
}

@media (max-width: 426px) {
  .form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 300px;
  }

  .formTitle {
    margin-top: 50px;
    font-size: 25px;
    line-height: 28px;
  }

  .input {
    order: 1;
  }

  .button {
    order: 3;
    margin: 0 auto;
  }

  .reCaptcha {
    order: 2;
    margin: 0 auto;
  }

  .note {
    order: 4;
  }

  .img {
    display: none;
  }

  .input {
    margin: 0;
  }
}
