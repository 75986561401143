* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.app {
  position: relative;
  min-height: 100vh;
}

.page {
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 115px;
  padding-bottom: 620px;
}

@font-face {
  font-family: 'Bebas';
  src: url('./assets/fonts/BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
}

@media (max-width: 426px) {
  .page {
    padding-top: 95px;
    padding-bottom: 950px;
  }
}
