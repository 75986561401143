.container {
  max-width: 1030px;
  margin: 15px auto 200px auto;
  font-family: Roboto;
}

.navList {
  margin: 50px 0;
  list-style-position: inside;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #3577f6;
}

.navList a {
  color: #3577f6;
  text-decoration: none;
  text-transform: capitalize;
}

.infoList {
  list-style: none;
}

.infoList li::before {
  display: block;
  content: ' ';
  margin-top: -85px;
  height: 85px;
  visibility: hidden;
  pointer-events: none;
}

.itemTitle {
  margin-top: 40px;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.itemParagraph {
  max-width: 1015px;
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 26px;
}

.bold {
  font-weight: 700;
}

@media (max-width: 1025px) {
  .container {
    padding: 0 20px;
  }
}
