.container {
  max-width: 1015px;
  margin: 13px auto 210px auto;
}

.info {
  margin-top: 56px;
}

.title {
  margin: 32px 0 24px 0;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.paragraph {
  font-family: Roboto;
  font-size: 16px;
  line-height: 26px;
}

.highlight {
  font-weight: 700;
  color: #0083cd;
  text-decoration: none;
}

.cardsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 1025px) {
  .container {
    padding: 0 20px;
  }
}
