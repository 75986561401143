.container {
  max-width: 1010px;
  margin: 15px auto 166px auto;
}

.title {
  margin: 0;
  margin-top: 56px;
  font-family: Roboto;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.paragraph {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-top: 25px;
}

@media (max-width: 1025px) {
  .container {
    padding: 0 20px;
  }
}
