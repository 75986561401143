.input {
  margin-bottom: 16px;
  max-width: 418px;
  width: 100%;
  height: 64px;
  margin-right: 16px;
  border: 1px solid;
  border-radius: 32px;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #4f4f4f;
  outline: none;
  border: 1px solid #e0e0e0;
}

.input:focus {
  border: 1px solid #3577f6;
}
