.button {
  width: 250px;
  height: 64px;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  border-radius: 32px;
  color: #fff;
  background: #3577f6;
  border: none;
  outline: none;
}

.button:hover {
  background: linear-gradient(180deg, #3577f6 0%, #255ecc 100%);
}
