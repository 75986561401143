/* FIRST SCREEN */

.firstScreenSection {
  display: flex;
  justify-content: space-between;
  margin: 78px 90px 0 100px;
}

.firstScreenSection .title {
  margin-top: 290px;
  font-family: Bebas;
  font-weight: 400;
  font-size: 70px;
  line-height: 84px;
}

.firstScreenSection .subtitle {
  margin-top: 30px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #828282;
}

.firstScreenSection .buttons {
  margin-top: 140px;
  max-width: 410px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.firstScreenSection .phone {
  width: 427px;
  height: 815px;
  background-image: url('assets/img/mp-phone1.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.link {
  text-decoration: none;
  color: #0083cd;
}

/* CAN I DO */

.canIDoSection {
  margin: 150px 200px 173px 155px;
  display: flex;
  justify-content: space-between;
}

.canIDoSection .contentContainer:first-child {
  max-width: 260px;
  text-align: right;
}

.canIDoSection .contentContainer {
  max-width: 340px;
}

.canIDoSection .title {
  font-family: Roboto;
  font-weight: 700;
  font-size: 48px;
  line-height: 44px;
  margin: 0;
}

.canIDoSection .paragraph {
  margin-bottom: 35px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #828282;
}

.paragraph.bold {
  font-weight: 700;
}

/* FEATURES SECTION */
.featuresSection .firstFeature {
  display: flex;
}

.featuresSection .secondFeature {
  display: flex;
  justify-content: flex-end;
}

.featuresSection .thirdFeature {
  display: flex;
}

.featuresSection .paragraphsContainer {
  color: #333333;
}

.firstFeature .paragraphsContainer {
  max-width: 500px;

  margin-top: 100px;
}

.secondFeature .paragraphsContainer {
  max-width: 400px;
  margin-right: 50px;
  margin-top: 220px;
  text-align: right;
}

.thirdFeature .paragraphsContainer {
  max-width: 500px;
  margin-top: 235px;
  margin-left: 10px;
}

.featuresSection .paragraph {
  margin-bottom: 25px;
  font-family: Bebas;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
}

.featuresSection .paragraph .highlight {
  color: #4a7ee1;
}

.phoneContainer {
  position: relative;
}

.firstFeature .phoneContainer {
  margin-left: 150px;
}

.secondFeature .phoneContainer {
  margin-right: 150px;
}

.thirdFeature .phoneContainer {
  margin-left: 110px;
}

.firstFeature .phone {
  width: 365px;
  height: 691px;
  margin-right: 50px;
  z-index: 1;
  background-image: url('assets/img/mp-phone2.png');
  background-repeat: no-repeat;
}

.firstFeature .phone::after {
  content: '';
  width: 331px;
  height: 373px;
  position: absolute;
  z-index: -1;
  top: 355px;
  left: 92px;
  background-image: url('assets/vector/mp-blob1.svg');
}

.secondFeature .phone {
  width: 365px;
  height: 691px;
  margin-right: 70px;
  z-index: 1;
  background-image: url('assets/img/mp-phone3.png');
  background-repeat: no-repeat;
}

.secondFeature .phone::after {
  content: '';
  width: 359px;
  height: 357px;
  position: absolute;
  z-index: -1;
  top: 410px;
  left: -80px;
  background-image: url('assets/vector/mp-blob2.svg');
}

.thirdFeature .phone {
  width: 365px;
  height: 691px;
  margin-right: 70px;
  z-index: 1;
  background-image: url('assets/img/mp-phone4.png');
  background-repeat: no-repeat;
}

.thirdFeature .phone::after {
  content: '';
  width: 483px;
  height: 458px;
  position: absolute;
  z-index: -1;
  top: 210px;
  left: -57px;
  background-image: url('assets/vector/mp-blob3.svg');
}

/* For Businesses */

.forBusinesses {
  margin: 160px 200px 108px 155px;
  display: flex;
  justify-content: space-between;
}

/* Devices Preview */

.devicesPreview {
  position: relative;
  height: 673px;
  margin-bottom: 130px;
}

.devicesPreview .macBook {
  max-width: 822px;
  width: 100%;
  height: 489px;
  position: absolute;
  top: 68px;
  background-image: url('assets/img/mp-macBook1.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.devicesPreview .macBook::after {
  content: '';
  width: 442px;
  height: 357px;
  position: absolute;
  z-index: -1;
  top: -95px;
  left: 424px;
  background-image: url('assets/vector/mp-blob4.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.devicesPreview .macBook::before {
  content: '';
  width: 213px;
  height: 391px;
  position: absolute;
  z-index: 1;
  top: 210px;
  right: 100px;
  background-image: url('assets/img/mp-phone5.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.devicesPreview .secondMacBook {
  max-width: 822px;
  width: 100%;
  height: 489px;
  position: absolute;
  top: 120px;
  right: -235px;
  background-image: url('assets/img/mp-macBook2.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.devicesPreview .modal {
  width: 216px;
  height: 261px;
  position: absolute;
  top: 440px;
  right: 26px;
  background-image: url('assets/img/mp-modal.png');
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 1025px) {
  .firstScreenSection .title {
    margin-top: 160px;
  }

  .firstScreenSection .phone {
    width: 400px;
  }

  .firstScreenSection .buttons {
    width: 410px;
  }

  .canIDoSection {
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: 50px auto;
  }

  .canIDoSection .contentContainer:first-child {
    width: 350px;
    text-align: center;
  }

  .canIDoSection .title {
    margin-bottom: 20px;
  }

  .firstFeature .phoneContainer {
    margin-left: 50px;
  }

  .secondFeature .phoneContainer {
    margin-right: 50px;
  }

  .thirdFeature .phoneContainer {
    margin-left: 70px;
  }

  .paragraphsContainer {
    padding: 0 10px;
  }

  .forBusinesses {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .devicesPreview {
    margin-bottom: 50px;
  }

  .devicesPreview .macBook {
    position: static;
    margin: 0 auto;
  }

  .devicesPreview .secondMacBook {
    display: none;
  }

  .devicesPreview .modal {
    display: none;
  }

  .devicesPreview .macBook::after {
    display: none;
  }
}

@media (max-width: 426px) {
  .firstScreenSection {
    margin: 0;
    padding: 0 20px;
  }

  .firstScreenSection .title {
    margin-top: 50px;
  }

  .firstScreenSection .buttons {
    max-width: 410px;
    width: 100%;
    margin-top: 70px;
    flex-direction: column;
    align-items: center;
    height: 150px;
  }

  .firstScreenSection .phone {
    display: none;
  }

  .featuresSection .firstFeature {
    flex-direction: column-reverse;
  }

  .featuresSection .secondFeature {
    flex-direction: column;
  }

  .featuresSection .thirdFeature {
    flex-direction: column-reverse;
  }

  .firstFeature .phone,
  .secondFeature .phone,
  .thirdFeature .phone {
    max-width: 365px;
    width: 100%;
    background-size: contain;
  }

  .firstFeature .phone::after,
  .secondFeature .phone::after,
  .thirdFeature .phone::after {
    display: none;
  }

  .firstFeature .phoneContainer,
  .secondFeature .phoneContainer,
  .thirdFeature .phoneContainer {
    margin: 0 20px;
  }

  .featuresSection .paragraphsContainer {
    margin: 0;
    margin-top: 30px;
    text-align: left;
  }

  .featuresSection .paragraph {
    font-size: 25px;
    line-height: 28px;
  }

  .canIDoSection {
    margin-top: 100px;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .canIDoSection .title {
    font-size: 38px;
    line-height: 38px;
  }

  .forBusinesses {
    margin: 0;
    margin-bottom: 60px;
  }

  .devicesPreview {
    display: none;
  }
}
