.container {
  font-family: Roboto;
  margin: 13px 0 130px 0;
}

.breadcrumbsContainer {
  max-width: 1125px;
  margin: 0 auto;
}

.features1 {
  max-width: 1125px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.features1 .img {
  position: relative;
  width: 709px;
  height: 723px;
  background-image: url('assets/img/fp-img1.png');
}

.features1 .img::after {
  content: 'December 24, 2020';
  position: absolute;
  top: 415px;
  left: 135px;
  z-index: 1;
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
  color: #fff;
}

.features2 {
  position: relative;
  max-width: 1157px;
  min-height: 722px;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.features2 .img {
  position: absolute;
  top: 19px;
  left: 218px;
  width: 415px;
  height: 552px;
  background-image: url('assets/img/fp-img2.png');
}

.features2::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 709px;
  height: 722px;
  top: 0;
  left: 0;
  background-image: url('assets/vector/fp-blob2.svg');
}

.features3 {
  position: relative;
  max-width: 1048px;
  min-height: 606px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.features3 .img {
  position: absolute;
  top: 10px;
  right: 77px;
  width: 415px;
  height: 590px;
  background-image: url('assets/img/fp-img3.png');
}

.features3::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 650px;
  height: 607px;
  top: 0;
  right: 0;
  background-image: url('assets/vector/fp-blob3.svg');
}

.features4 {
  position: relative;
  max-width: 1036px;
  min-height: 640px;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.features4 .img {
  position: absolute;
  top: 20px;
  left: 76px;
  width: 415px;
  height: 560px;
  background-image: url('assets/img/fp-img4.png');
}

.features4::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 595px;
  height: 640px;
  top: 0;
  left: 0;
  background-image: url('assets/vector/fp-blob4.svg');
}

.features5 {
  position: relative;
  max-width: 953px;
  min-height: 429px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.features5 .img {
  position: absolute;
  top: 102px;
  right: 13px;
  width: 383px;
  height: 210px;
  background-image: url('assets/img/fp-img5.png');
}

.features5::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 467px;
  height: 429px;
  top: 0;
  right: 0;
  background-image: url('assets/vector/fp-blob5.svg');
}

.title {
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.info {
  max-width: 372px;
  font-size: 16px;
  line-height: 26px;
}

@media (max-width: 1025px) {
  .container {
    padding: 0 20px;
  }

  .features2 .img {
    left: 90px;
  }

  .features2::after {
    left: -100px;
  }
}

@media (max-width: 426px) {
  .features1,
  .features2,
  .features3,
  .features4,
  .features5 {
    flex-direction: column;
    min-height: 100%;
    margin-bottom: -20px;
  }

  .features1 {
    margin-top: 80px;
  }

  .features1 .img {
    height: 500px;
    background-image: url('assets/img/fp-img1-svg.svg');
  }

  .features1 .img::after,
  .features2::after,
  .features3::after,
  .features4::after,
  .features5::after {
    display: none;
  }

  .container .img {
    position: static;
    margin-top: 20px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
