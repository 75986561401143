.title {
  margin: 0;
  font-family: Roboto;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  color: #000;
}

.link {
  display: inline-block;
  text-decoration: none;
}

.separator {
  margin-left: 42px;
  font-size: 32px;
  line-height: 37px;
  color: #3577f6;
}

@media (max-width: 426px) {
  .title {
    font-size: 25px;
    line-height: 30px;
  }

  .separator {
    margin-left: 22px;
    font-size: 25px;
    line-height: 30px;
  }
}
