.card {
  margin-top: 56px;
  overflow: visible;
}

.icon {
  margin-left: -25px;
  width: 134px;
  height: 134px;
}

.title {
  margin: 10px 0 24px 0;
  font-family: Roboto;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.paragraph {
  max-width: 439px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 26px;
}
