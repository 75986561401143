.container {
  font-family: Roboto;
  max-width: 1015px;
  margin: 13px auto 195px auto;
}

.title {
  margin-top: 56px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.text {
  margin-top: 24px;
  font-size: 16px;
  line-height: 26px;
}

.form {
  margin-top: 65px;
  max-width: 568px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inputsContainer {
  display: flex;
  justify-content: space-between;
}

.input {
  width: 279px;
  height: 44px;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  outline: none;
  padding: 11px;
  background: #fcfcfc;
}

.input::placeholder {
  font-family: Bebas;
  font-size: 20px;
  line-height: 24px;
  color: #b5b5b5;
}

.textarea {
  outline: none;
  resize: none;
  margin-top: 25px;
  padding: 16px 10px;
  width: 100%;
  height: 271px;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  font-size: 16px;
  line-height: 26px;
  color: #b5b5b5;
  background: #fcfcfc;
}

.buttonsContainer {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bold {
  font-weight: 700;
}

@media (max-width: 1025px) {
  .container {
    padding: 0 20px;
  }

  .form {
    margin: 50px auto;
  }
}

@media (max-width: 426px) {
  .inputsContainer {
    flex-direction: column;
    height: 113px;
  }

  .input {
    width: 100%;
  }

  .buttonsContainer {
    flex-direction: column;
    height: 160px;
  }
}
