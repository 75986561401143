.Toastify__toast-container {
  width: 324px;
  height: 120px;
  position: fixed;
  right: 65px;
  top: 40px;
  overflow: visible;
}

.Toastify__toast {
  display: block;
  width: 324px;
  height: 114px;
  box-shadow: 0px 10px 20px rgba(120, 120, 120, 0.25);
  border-radius: 4px;
  background: #333e53;
  background-image: url('assets/vector/alert-bg.svg');
  overflow: visible;
  padding: 18px 10px;
}

.Toastify__close-button {
  position: absolute;
  top: -28px;
  right: -28px;
  width: 24px;
  height: 24px;
  background: #333e53;
  border-radius: 32px;
  background-image: url('assets/vector/close-icon.svg');
  color: #000;
  opacity: 1;
}

.Toastify__close-button > svg {
  display: none;
}

.Toastify__progress-bar {
  position: absolute;
  min-width: 308px;
  left: 8px;
  right: 8px;
  bottom: 15px;
  height: 8px;
  border: 2px solid #fff;
  background: linear-gradient(270deg, #7c96c8 0%, #2466f3 100%);
}

@media (max-width: 426px) {
  .Toastify__close-button {
    position: absolute;
    top: -28px;
    right: 0;
  }

  .Toastify__toast {
    margin-left: 10px;
  }
}
