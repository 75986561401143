.wrapper {
  position: fixed;
  width: 100%;
  background: #3577f6;
  z-index: 10;
}

.wrapper::after {
  content: '';
  width: 100%;
  height: 65px;
  position: absolute;
  top: 53px;
  background-image: url('assets/vector/header-wave1.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 65px;
  /* preserveAspectRatio="none" to stretch svg!!! */
}

.wrapper::before {
  content: '';
  width: 100%;
  height: 65px;
  position: absolute;
  top: 62px;
  background-image: url('assets/vector/header-wave2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 65px;
}

.container {
  margin: 0 auto;
  padding: 0 62px 0 32px;
  max-width: 1440px;
  height: 67px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  font-family: Roboto;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  display: flex;
}

.menuItem {
  margin-left: 56px;
  color: #ffffff;
  text-decoration: none;
}

.menuItem:hover {
  color: #002d83;
}

.button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  display: none;
  height: 32px;
  width: 32px;
}

.button:active {
  background: none;
}

.homeLink {
  display: none;
}

@media (max-width: 426px) {
  .active {
    transition: transform 0.1s linear;
    transform: translateX(0);
  }

  .hide {
    transition: transform 0.1s linear;
    transform: translateX(-426px);
  }
  .wrapper::after,
  .wrapper::before {
    height: 40px;
    background-size: 120% 40px;
  }

  .container {
    padding: 0 15px;
  }

  .logo {
    display: none;
  }

  .button {
    display: block;
  }

  .menu {
    font-size: 13px;
  }

  .menuItem {
    margin-left: 13px;
  }

  .homeLink {
    display: block;
  }
}
