.alert {
  font-family: Roboto;
  color: #ffffff;
  font-size: 13px;
  line-height: 15px;
}

.bold {
  font-weight: bold;
}
