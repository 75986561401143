.container {
  margin-top: 25px;
}

.list {
  list-style-type: none;
}

.title {
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

.item {
  position: relative;
  max-width: 982px;
  margin-left: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.item::after {
  content: '\2022';
  display: inline;
  font-size: 22px;
  line-height: 22px;
  position: absolute;
  left: -15px;
  top: 87px;
}

.bold {
  font-weight: 700;
}
